import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { Hero } from '../components/hero';
import * as imageStyles from '../components/image.module.css';
import { Layout } from '../components/layout';

const NotFoundPage = () => {
  return (
    <Layout
      title="Bridge & Associates Appraisals Ltd.: Page not found"
      description="We're sorry, we couldn't find the page you were looking for"
    >
      {({ setIsMenuOpen }) => (
        <Hero
          slogan="Page not found"
          setIsMenuOpen={setIsMenuOpen}
          noArrow
          image={
            <StaticImage
              loading="eager"
              alt="An ice cream cone splattered on the ground"
              layout="fullWidth"
              className={imageStyles.background}
              src={'../images/404.jpeg'}
            />
          }
        />
      )}
    </Layout>
  );
};

export default NotFoundPage;
